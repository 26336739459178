import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Sitemap({ data }) {
    return (
        <Layout slug="/sitemap/" {...data.meta.nodes[0]}>
            <div class="c-page-title">
                <h1>サイトマップ</h1>
            </div>

            <div class="p-sitemap">
                <ul>
                    <li><Link to="/naruniha">歯科衛生士になるには</Link></li>
                    <li><Link to="/shigoto">歯科衛生士の仕事内容</Link></li>
                    <li><span>歯科衛生士の魅力</span>
                        <ul>
                            <li><Link to="/salary">大卒より高い初任給</Link></li>
                            <li><Link to="/shikaku">一生使える国家資格</Link></li>
                            <li><Link to="/employment">就職率はなんと9割以上</Link></li>
                            <li><Link to="/hobby">趣味や遊びも両立</Link></li>
                            <li><Link to="/medical">患者さんを笑顔にする医療職</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/voice">学生VOICE</Link></li>
                    <li><Link to="/active">歯科衛生士の活躍</Link></li>
                    <li><Link to="/future">歯科衛生士のこれから</Link></li>
                    <li><Link to="/qa">歯科衛生士Q&amp;A</Link></li>
                    <li><Link to="/school">全国の歯科衛生士学校</Link>
                        <ul>
                            <li><Link to="/school/hokkaido">北海道</Link></li>
                            <li><Link to="/school/tohoku">東北</Link>
                                <ul>
                                    <li><Link to="/school/tohoku/aomori">青森県</Link></li>
                                    <li><Link to="/school/tohoku/iwate">岩手県</Link></li>
                                    <li><Link to="/school/tohoku/miyagi">宮城県</Link></li>
                                    <li><Link to="/school/tohoku/akita">秋田県</Link></li>
                                    <li><Link to="/school/tohoku/yamagata">山形県</Link></li>
                                    <li><Link to="/school/tohoku/fukushima">福島県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/kanto">関東</Link>
                                <ul>
                                    <li><Link to="/school/kanto/ibaraki">茨城県</Link></li>
                                    <li><Link to="/school/kanto/tochigi">栃木県</Link></li>
                                    <li><Link to="/school/kanto/gunma">群馬県</Link></li>
                                    <li><Link to="/school/kanto/saitama">埼玉県</Link></li>
                                    <li><Link to="/school/kanto/chiba">千葉県</Link></li>
                                    <li><Link to="/school/kanto/tokyo">東京都</Link></li>
                                    <li><Link to="/school/kanto/kanagawa">神奈川県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/koshinetsu">甲信越</Link>
                                <ul>
                                    <li><Link to="/school/koshinetsu/yamanashi">山梨県</Link></li>
                                    <li><Link to="/school/koshinetsu/nagano">長野県</Link></li>
                                    <li><Link to="/school/koshinetsu/niigata">新潟県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/tokai">東海</Link>
                                <ul>
                                    <li><Link to="/school/tokai/aichi">愛知県</Link></li>
                                    <li><Link to="/school/tokai/gifu">岐阜県</Link></li>
                                    <li><Link to="/school/tokai/mie">三重県</Link></li>
                                    <li><Link to="/school/tokai/shizuoka">静岡県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/hokuriku">北陸</Link>
                                <ul>
                                    <li><Link to="/school/hokuriku/toyama">富山県</Link></li>
                                    <li><Link to="/school/hokuriku/ishikawa">石川県</Link></li>
                                    <li><Link to="/school/hokuriku/fukui">福井県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/kinki">近畿</Link>
                                <ul>
                                    <li><Link to="/school/kinki/shiga">滋賀県</Link></li>
                                    <li><Link to="/school/kinki/kyoto">京都府</Link></li>
                                    <li><Link to="/school/kinki/osaka">大阪府</Link></li>
                                    <li><Link to="/school/kinki/hyogo">兵庫県</Link></li>
                                    <li><Link to="/school/kinki/nara">奈良県</Link></li>
                                    <li><Link to="/school/kinki/wakayama">和歌山県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/chugoku">中国・四国</Link>
                                <ul>
                                    <li><Link to="/school/chugoku/tottori">鳥取県</Link></li>
                                    <li><Link to="/school/chugoku/shimane">島根県</Link></li>
                                    <li><Link to="/school/chugoku/okayama">岡山県</Link></li>
                                    <li><Link to="/school/chugoku/hiroshima">広島県</Link></li>
                                    <li><Link to="/school/chugoku/yamaguchi">山口県</Link></li>
                                    <li><Link to="/school/chugoku/tokushima">徳島県</Link></li>
                                    <li><Link to="/school/chugoku/kagawa">香川県</Link></li>
                                    <li><Link to="/school/chugoku/ehime">愛媛県</Link></li>
                                    <li><Link to="/school/chugoku/kochi">高知県</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/school/kyushu">九州・沖縄</Link>
                                <ul>
                                    <li><Link to="/school/kyushu/fukuoka">福岡県</Link></li>
                                    <li><Link to="/school/kyushu/saga">佐賀県</Link></li>
                                    <li><Link to="/school/kyushu/nagasaki">長崎県</Link></li>
                                    <li><Link to="/school/kyushu/kumamoto">熊本県</Link></li>
                                    <li><Link to="/school/kyushu/oita">大分県</Link></li>
                                    <li><Link to="/school/kyushu/miyazaki">宮崎県</Link></li>
                                    <li><Link to="/school/kyushu/kagoshima">鹿児島県</Link></li>
                                    <li><Link to="/school/kyushu/okinawa">沖縄県</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><Link to="/news">お知らせ</Link></li>
                    <li><Link to="/about">めざせ！歯科衛生士について</Link></li>
                    <li><a href="https://www.guppy.co.jp/contact/" target="_blank">お問い合わせ</a></li>
                    <li><Link to="/terms">利用規約</Link></li>
                    <li><Link to="/policy">プライバシーポリシー</Link></li>
                    <li><Link to="/sitemap">サイトマップ</Link></li>
                </ul>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        meta: allMeta(filter: {slug: {eq: "/sitemap/"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`